<template>
  <div>
    <v-simple-table
      class="table-padding-2"
      fixed-header
      height="calc(100vh - 400px)"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.warehouse_code')"
                :placeholder="$t('labels.warehouse_code')"
                name="code_name"
                sort-name="code_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader" style="width: 120px">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="active"
                sort-name="active"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>{{ item.code_name }}</td>
            <td>{{ item.active ? "Yes" : "No" }}</td>
            <td>
              <v-switch
                class="switch-center mt-0 pt-0"
                v-model="item.status"
                @change="switchUse(item)"
                :key="`item_${item.id}`"
                hide-details
                dense
              ></v-switch>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        v-if="totalPage > 1"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
export default {
  name: "WarehouseUse",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
  },
  props: {
    packaging: {
      type: Object,
      default: () => {},
    },
    supplier: {
      type: Object,
      default: () => {},
    },
    usingIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filters: {},
    items: [],
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    statusOptions: [...YES_NO_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getList: debounce(function () {
      httpClient
        .post("/warehouse/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((item) => ({
            ...item,
            status: this.usingIds.includes(item.id),
          }));
        });
    }, 500),
    async switchUse(item) {
      if (!this.checkPermission(["super_admin"])) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        if (this.packaging && this.packaging.id) {
          await httpClient.post("/packaging/v1/using-update", {
            id_packaging: this.packaging.id,
            id_warehouse: item.id,
            status: item.status,
          });
        }
        if (this.supplier && this.supplier.id) {
          await httpClient.post("/packaging/v1/supplier-using-save", {
            id_supplier: this.supplier.id,
            id_warehouse: item.id,
            status: item.status,
          });
        }

        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
